:root {
    --padding: 25px; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 25px; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}


@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Montserrat.css";
@import "/fontmodules/Hind.css";
@import "/cssmodules/animate.css";
/*
%7CHind
%7CRobot
%7CRoboto+Condensed
%7CNews+Cycle
%7CWork+Sans
%7CSignika
%7CTitillium+Web

@import "/theme/intendit/css/modules/fade_zoom.css";
@import "/cssmodules/photoswipe.css";
@import "/cssmodules/animate.css";
*/

.ettsmarthem_sektion {  }


/*quicklinks som runda ikoner när man scrollar*/      
.quicklinks {position:fixed; top:110px; right:10px; text-align:right; z-index:25; font-size:1.2em; opacity:0;  transition:opacity 0.3s ease-in 0s;}     
body.scrolled  .quicklinks  {opacity:1;  transition:opacity 0.3s ease-in 0s;}
.quicklink  {display:block; clear:both; margin: 0 0 10px;   }       
       
.quicklink .fa  {color:#fff; background:#EF5747; padding: 13px 0 0; height: 55px; width: 55px; border-radius:50%; margin: 0 0 7px 0px; text-align:center; font-size:1.4em;  }      
   


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 300; 
      color: #404040;
      line-height:1.25em;
    font-size:1.03em; 
      font-family: 'Hind', sans-serif;
      /*background: url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/;}
 
/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh;  } 
.startsida .wrapper {min-height:1em; }
.container {width:100%;}
.container.lrg {max-width: 1300px;}
.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
.sektion a, .sektion a:hover, .sektion a:focus {color:inherit;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:360px;  max-width:40%; height:auto;  margin: 0 0 30px 30px; overflow:hidden;}
.box_bild, .listing_bild {width:100%; flex:none; max-width:100%;} /*säkra vyn i IE*/
.bild img, .box_bild img, .listing_bild img, .galleri_bild img {width:100%; height:auto; } /*säkra vyn i IE*/

/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }

#Hur-bygger-vi-då-det-smarta-huset-tillsammans ul {list-style:none; padding: 0; }
#Hur-bygger-vi-då-det-smarta-huset-tillsammans li {display: table;float: left;margin-right: 2%;width: 48%; }
#Hur-bygger-vi-då-det-smarta-huset-tillsammans ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;} 

.box ul {list-style:none; padding: 0; margin:0; }
.box ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;} 


/*------------readmore & buttons----------------*/
.button, .readmore, button {background: rgba(0, 0, 0, 0.15) !important; transition:background 0.5s ease-in 0s; padding: 6px 0 8px; display:block; width: 100%;  margin:0; line-height: 1.2em; border:none;}
.button:hover, .readmore:hover, button:hover {background:rgba(0, 0, 0, 0.3)!important; color:#fff !important; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;  text-align: center; border-radius: 0.2em;} 
.box .button {color:#fff; } 

/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:rgba(0, 0, 0, 0.15);}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(0, 0, 0, 0.5); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
.kontakt_sektion .fa.round {background:rgba(0, 0, 0, 0.15); font-size:1.3em; width: 45px; height: 45px; padding: 12px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
.box .fa {display:block; margin-bottom:15px; text-align:center; }

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }
.record {padding: 20px 0; border-bottom:1px solid #e4e4e4; }

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; line-height:1.1em; padding: 0px; font-weight:300;   font-family: 'Montserrat', sans-serif; }
h1{font-size: 2.2em;padding: 0px 0 20px; /*text-transform:uppercase;*/}
h2{font-size: 1.45em;}
h3{font-size: 1.25em;  }
h4{font-size: 1em;}
h5{font-size: 1em;}

.highlight {font-size:1.4em; line-height:1.2em; padding: 20px 0 ; color:#e4e4e4; font-weight:300; }
.sida.listing h1 {padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid #e4e4e4;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:absolute; right:10px; top:90px; z-index:1;  display:none;  } /*display none by default*/
.topbild {height:45vh; max-height:45vw; background-position: center center; background-size:cover; position: relative;}

/*----------------------Bildspel-----------------------*/
.intro_sektion .slick-slider {margin:0 auto; position: relative; border-bottom: 10px solid #d0d0d0; }
.intro_sektion .slick-slide {background-size:cover; background-position: center center; min-height:75vh; display:flex !important;  }   
.slick {visibility: hidden; min-height:75vh; }
.intro_sektion .slick-initialized {visibility: visible;}
 
 


/*----------------------Startsidatext-----------------------*/
.startsidatext {color: rgba(0, 0, 0, 0.9); opacity:1 !important;    transition:all 0.5s ease-in 0s;
    padding: 200px 0 190px;
    text-shadow: 0 0 23px rgba(255, 255, 255, 0.6);
    width: 100%;  }
.startsidatext h1 {font-size: 3em;
    font-weight: 300;
    padding: 0 0 15px;
    text-align: center;
    text-transform: uppercase; }
.startsidatext_textdiv {text-align:center;  font-size:1.5em; font-weight:500; line-height:1.2em;  } 


body.scrolled .startsidatext {opacity:0  !important;  transition:all 0.5s ease-in 0s;}

/*
.startsidatext:before {content:""; position:absolute; top:-40px; left:50%; margin: 0 0 0 -50%; height:40px; width:100%; z-index:2; 
background: url('/theme/intendit/graphics/tip.png') center top/auto 100% no-repeat ;}*/

/*----------------------Boxar-------------------------------*/
.boxar {padding: 0px 0 40px; position:relative; }
.box { border-radius:6px; color:#fff; font-size:1.1em; }
.box h2 {padding: 0 0 10px ; }

.box:nth-child(1) {background: rgba(89, 163, 174, 0.85);  margin-top:-25vh;  }
.box:nth-child(2) {background: rgba(107, 195, 207, 0.85);  margin-top:-20vh;}
.box:nth-child(3) {background: rgba(20, 20, 20, 0.8);  margin-top: -15vh;
    min-height: calc(100vh - 75vh - 40px);}

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------ALTERNATIVE INDEX - Onepage-----------------------------------------------------------*/
.sektion {width:100%; padding: 100px 0; position:relative;}
.sektion.full {min-height: 100vh;}
.banner_sektion {min-height:55vh; }
.banner_sektion h1 {font-size:4em; text-align:center; }

/*arrows*/
.scrollup {opacity:0; position:fixed; bottom:30px; right:30px; z-index:2; color:#fff; font-size:4em; cursor:pointer; transition:opacity 0.5s ease-in 0s;}
body.scrolled .scrollup {opacity:0.3;}  body.scrolled .scrollup:hover {opacity:1; transition: opacity 0.5s ease-in 0s; }

.arrow .fa {  background:#EF5747; border-radius: 50%; color: #ffffff; display: block; font-family: FontAwesome;font-size: 5em;
height: 80px;left: 50%;margin: -40px 0 0 -40px; padding: 4px 0 0; position: absolute;text-align: center; width: 80px; z-index:5;}


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #d0d0d0; }
.galleri_bild a {display:block; }
.galleri_bild {box-shadow: 1px 1px 0 0 #e4e4e4;} .galleri_bild:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.galleri_bild img {padding:20px;}
.thumbtitel {font-size:90%; }  

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; }
.loggagalleri .logga div {text-align:center; width: 100%; flex:0 0 auto;} 
.loggagalleri .logga img {height:auto;}
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 
 
 
/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
.kontakt_sektion {padding-bottom: 0; padding-top: 60px; background:#ef5747 ; color:#fff; }
.kontakt_sektion > .container:first-child {padding-bottom: 60px;}
.kontakt_sektion > .container > .twelve {margin-bottom:0; }

.kontakt_sektion .boltforms-row {width:100%;}
.kontakt_sektion .boltforms-row label {font-weight: 300; margin-bottom: 0;}
.kontakt_sektion .boltforms-row input, .kontakt_sektion .boltforms-row textarea{width:100%; color:#606060; margin-bottom:0; }

/*Googlekarta*/
.map-canvas {height: 30vh; }
.map-canvas img {max-width: none;}

.intendit, .intendit a  {color:#505050 !important; font-size:0.95em; letter-spacing:0.3pt; }
.intendit  {text-align:center; padding: 5px 0 10px; background:#050505; }


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
/*backbutton på separata sidor*/
.backbutton {padding:0px 20px 0 0; font-size: 1.1em;}
.backbutton .fa {margin-bottom: 2px; }


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform:none; width:100%; background:#fff; z-index:10; position:fixed; top:0; min-height:70px;  border-top: 10px solid rgba(107, 195, 207, 0.85);
background-clip:padding-box;   }
body.scrolled header {}

/*Logotype*/
.logo {padding:15px; box-sizing:border-box; } 
.logo img {max-height: 32px; width:auto; max-width:calc(100vw - 100px)}
.logo a {padding:0; display:block;}  

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; text-transform:uppercase; font-size:0.9em;  letter-spacing:0.5pt; border-bottom: 2px solid rgba(0, 0, 0, 0);  /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a { border-bottom: 2px solid #72B1BA; }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family: FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }

/*Mobile menu under 800px - fixed header*/
.mobile-menu header {box-shadow:none; flex-wrap:nowrap; border-bottom: 1px solid #e4e4e4;} 
.mobile-menu nav ul.menu {margin-top:1px; background:rgba(255, 255, 255, 0.9); }
.mobile-menu nav ul.menu.show { max-height:calc(100vh - 80px); }  /*räknar höjd - fixed header*/
.mobile-menu nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
.mobile-menu nav .menu .dropdown ul {padding:0; left:0px;} 
.mobile-menu nav ul.menu li.dropdown > a:after {display:none; }
 
nav #menu-icon {padding: 20px 20px 20px 0;}
nav #menu-icon span {border-radius:3px;}

  

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}  
    
.startsidatext h1 {
    font-size: 3em;}
    
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    
    .quicklink .fa {
     font-size: 1.2em;
    height: 45px;
     padding: 10px 0 0;
    text-align: center;
    width: 45px;
}
    
    .startsidatext {padding: 170px 0 190px; }
    
    .box:nth-child(3) {margin-top:0; }  
.startsidatext h1 {
    font-size: 2.5em;}
.startsidatext_textdiv {
    font-size: 1.3em;
     line-height: 1.1em;
 }
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    
/*Slider height*/  
.slick, .slick-slide {min-height:55vw;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.galleri_bild:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  }      
   
/*Sidfot*/   
.sidfot .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
.sidfot .s-twelve:last-child {border-bottom: none; } 
    
#Hur-bygger-vi-då-det-smarta-huset-tillsammans li {
   
    margin-right: 0;
    width:100%;
}
    
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
    .quicklinks {display:none; }
    .box {padding: 20px; margin-bottom: 1em; }
    
    h1 {
        font-size: 1.8em;}
        
    .startsidatext {
  
        padding: 115px 0 40px;}
        
        
    .boxar {padding: 20px 0; }  
    .box {margin-top:0 !important; }
  
.startsidatext h1 {
    font-size: 2em;
}
.startsidatext_textdiv {
    font-size: 1.2em;
  }   
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.galleri_bild:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/     
}

@media (max-width: 350px)
{
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 10px; float:none; }     
.bild img {width:100%; height:auto;}    
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/

/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}

















/*----------------------Zoomboxar-------------------------------
.zoombox { height:20vw; overflow:hidden; position:relative;}
.zoombox_innerdiv {height:100%; width:100%; background-position: 50% 50%; background-size:cover; transform: scale(1); position:absolute; transition: transform 3s ease-in 0s;}
.zoomboxar > a:hover .zoombox_innerdiv {transform: scale(1.2); transition: transform 3s ease-in 0s;} 
.zoombox_textdiv {height:100%; width: 100%; overflow:hidden; margin:0;  padding: 0px 2em; text-align:center; position:absolute;} */

/*----------------------Fadeboxar-------------------------------
.fadebox {height:20vw; overflow:hidden; display:block; background-size:cover; background-position: center center;}
.fadebox_innerdiv {height:100%; background:rgba(0, 0, 0, 0.6 ); opacity:0; transition: opacity 0.5s ease-in 0s; } 
.fadebox:hover .fadebox_innerdiv {opacity:1;} 
.fadebox_textdiv {padding: 0 2em; color:#fff; text-align: center; position: relative; }*/

/*--------------------Mediaquerys fadeboxar--------------------
@media (max-width: 1300px) 
{
.fadebox, .zoombox {height:23vw}
}
@media (max-width: var(--breakpoint-m))
{
.fadebox_textdiv span, .zoombox_textdiv span {display:none;}
.fadeboxar.container, .zoomboxar.container, .fadeboxar.container.lrg, .zoomboxar.container.lrg {width: 100%;} 
}
@media (max-width: var(--breakpoint-s))
{
.fadebox, .zoombox {height:30vw;}
.fadebox_innerdiv, .zoombox_textdiv {background:rgba(0, 0, 0, 0.3); opacity:1; color:#fff; }
.zoomboxar > a:hover .zoombox_innerdiv, .zoombox_innderdiv {transform:none;}
}
@media (max-width: var(--breakpoint-xs))
{
.fadebox, .zoombox {height:50vw;}
}

*/
